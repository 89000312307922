import "./FarmSummary.css";


export default function FarmSummary() {
    return (
        <div className="Container">
            <div className="Title">Your farm at a glance</div>
            <div className="StatsPanel">
                <Stat key="1" id="1" name="Temperature" value="2°C" insight="Very cold. Take caution with vulnerable cows."
                    sliderProportion={0.35} gradients={["#325aa8", "#3eab50", "red"]}/>
                <Stat key="2" id="2" name="Milk Production" insight="5% higher than this time last month."
                      value={<div>20L<span className="Stat-Value--unit">/cow</span></div>} sliderProportion={0.55}
                    gradients={["red", "orange", "#3eab50"]}/>
                <Stat key="3" id="3" name="Fertility Score" insight="The chance of fertilisation is currently high."
                    value="82.5" sliderProportion={0.8} gradients={["red", "orange", "#3eab50"]}/>
            </div>
        </div>
    )
}

function Stat(props) {
    return (
        <div className="Stat">
            <div className="Stat-Title">{props.name}</div>
            <CircularSlider value={props.sliderProportion} gradients={props.gradients} id={props.id}/>
            <div className="Stat-Value">{props.value}</div>
            <div className="Stat-Insight">{props.insight}</div>
        </div>
    )
}

function CircularSlider(props) {

    function getCoordinates(radius, angleInDegrees) {
        const angleInRadians = (angleInDegrees * Math.PI) / 180;
        const x = radius * Math.cos(angleInRadians);
        const y = radius * Math.sin(angleInRadians);

        return [x, y]
    }

    const BarTick = (props) => {
        const {portion, cX, cY, r, sW} = props;

        const [x1, y1] = getCoordinates(r, (180 * portion) + 180)
        const [x2, y2] = getCoordinates(r + sW, (180 * portion) + 180)

        return (
            <line x1={x1 + cX} y1={y1 + cY} x2={x2 + cX} y2={y2 + cY} className="CircularSlider-TickSVG"/>
        )
    }

    const dynamicStyle = {
        stroke: "url(#BarGradient-" + props.id + ")",
    }

    return (
        <div>
            <svg width="100" height="100">
                <path d=" M 10 50 A 40 40 180 0 1 90 50" className="CircularSlider-BarSVG" style={dynamicStyle}/>
                <BarTick portion={props.value} cX={50} cY={50} r={36} sW={8}/>
                <defs>
                    <linearGradient id={"BarGradient-" + props.id}>
                        {
                            props.gradients.map((grad, i) => {
                                return <stop key={i} className={"stop"+i} offset={((i / (props.gradients.length - 1)) * 100) + "%"}
                                             stopColor={grad}/>
                            })
                        }
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}