import "./HerdMap.css";
import {useRef} from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import Map, {Marker, useControl} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Cow from '../assets/cow.png';

const DrawControlStyle = [{
    "id": "gl-draw-polygon-fill-static",
    "type": "fill",
    "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
    "paint": {
        "fill-color": "rgb(255, 162, 0)",
        "fill-outline-color": "rgb(255, 162, 0)",
        "fill-opacity": 0.2
    }
},
    {
        "id": "gl-draw-polygon-stroke-static",
        "type": "line",
        "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
        "layout": {
            "line-cap": "round",
            "line-join": "round"
        },
        "paint": {
            "line-color": "rgb(255, 162, 0)",
            "line-dasharray": [0.2, 2],
            "line-opacity": 0.8,
            "line-width": 2
        }
    }]

export default function HerdMap() {
    const BathFieldFeature = {
        "id": "6d0f9797966c5f9167ecf732cb4fecc8",
        "type": "Feature",
        "properties": {},
        "geometry": {
            "coordinates": [
                [
                    [
                        -2.396540269908911,
                        51.343657785647395
                    ],
                    [
                        -2.3962526842701095,
                        51.342870893712984
                    ],
                    [
                        -2.3969150393272685,
                        51.342758257505665
                    ],
                    [
                        -2.39624175124996,
                        51.34194163309229
                    ],
                    [
                        -2.392573618719581,
                        51.343015131050095
                    ],
                    [
                        -2.3932050797553757,
                        51.34412673899703
                    ],
                    [
                        -2.396540269908911,
                        51.343657785647395
                    ]
                ]
            ],
            "type": "Polygon"
        }
    };

    function DrawControl(props) {
        const Draw = useRef(new MapboxDraw(props));

        useControl(
            () => Draw.current,
            ({map}) => {
                map.on('load', () => {
                    Draw.current.add(BathFieldFeature);
                })
            },
            () => {}
        );

        return null;
    }

    const modes = MapboxDraw.modes;
    modes.static = StaticMode;

    const cowLocations = [
        [51.34352776897422, -2.396035517843257],
        [51.34342054064374, -2.395627822076069],
        [51.343541172497915, -2.3953596011766036],
        [51.34327980307925, -2.3953810588485602],
        [51.34236164740924, -2.3960677043511933],
    ]

    return (
        <div className="Container">
            <div className="Title">🟠 Bath Field</div>
            <div className="HerdMap">
            <Map
                initialViewState={{
                    longitude: -2.394801732341264,
                    latitude: 51.343192672209916,
                    zoom: 15.5
                }}
                style={{width: "100%", height: "100%", borderRadius: "10px"}}
                mapStyle="mapbox://styles/mapbox/satellite-v9"
                mapboxAccessToken="pk.eyJ1Ijoiam9laGQiLCJhIjoiY2xnOGRlNGs2MG16MDNzcGttMjFtYnM0OCJ9.Vjsp2UVv9YOyciXW4Nzvjg"
            >
                <DrawControl
                    displayControlsDefault={false}
                    defaultMode="static"
                    modes={modes}
                    styles={
                        DrawControlStyle
                    }
                />
                {cowLocations.map(([lat, lng], i) =>
                    <Marker key={i} latitude={lat} longitude={lng}>
                        <img src={Cow} width="20" height="20" alt="cow marker"/>
                    </Marker>)}
            </Map>
            </div>
        </div>
    )
}