import "./FarmTimeline.css";
import ColdIcon from '../assets/cold.png';
import SideCow from '../assets/side_cow.png';
import FaceCow from '../assets/face_cow.png';


export default function FarmTimeline () {
    return (
            <div className="Container">
                <div className="Title">Today's Highlights</div>
                <div className="TimelinePanel">
                    <TimelineEntry title="Maisy" icon={ColdIcon} content={<div>Had a low temperature of 2°C at 3:14AM last night.<br/>Tip: Check for a leak in the barn, or change their bedding</div>} />
                    <TimelineEntry title="Dorris" icon={SideCow} content={<div>Hit a new highest milk volume for the field of 26L</div>}/>
                    <TimelineEntry title="Bath Field" icon={FaceCow} content={<div>Overall fertility score hit the national average - you currently have high probability of pregnancy.</div>}/>
                    <TimelineEntry title="Pip" icon={SideCow} content={<div>Has been displaying signs of pregnancy.</div>}/>
                    <TimelineEntry title="Mary" icon={SideCow} content={<div>Gave birth yesterday!</div>}/>
                    <TimelineEntry title="Yaz" icon={SideCow} content={<div>Has been walking with a limp for the last few hours.</div>}/>
                </div>
            </div> )
}

function TimelineEntry (props) {
    return (
            <div className="Entry">
                <div className="Entry-Container">
                    <div className="Entry-Title">{props.title}</div>
                    <div className="Entry-ContentContainer">
                        <div className="Entry-Content">{props.content}</div>
                        <div className="Entry-IconContainer">
                            <img className="Entry-Icon" src={props.icon} alt={"cow icon"}/>
                        </div>
                    </div>
                </div>
            </div>
    )
}