import "./HerdHeatmap.css";
import Map, {Source, Layer, useControl} from 'react-map-gl';
import {useRef} from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import 'mapbox-gl/dist/mapbox-gl.css';

const MAX_ZOOM_LEVEL = 18;

export const heatmapLayer = {
    id: 'heatmap',
    maxzoom: MAX_ZOOM_LEVEL,
    type: 'heatmap',
    paint: {
        'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 10, 1, MAX_ZOOM_LEVEL, 3],
        'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(33,102,172,0)',
            0.2,
            'rgb(103,169,207)',
            0.4,
            'rgb(209,229,240)',
            0.6,
            'rgb(253,219,199)',
            0.8,
            'rgb(239,138,98)',
            0.9,
            'rgb(255,201,101)'
        ],
        'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 10, 2, MAX_ZOOM_LEVEL, 20],
        'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 10, 1, MAX_ZOOM_LEVEL, 0]
    }
};

const data = {
    "type": "FeatureCollection",
    "features": [
            {
                "type": "Feature",
                "properties": { "id": "ak16994521"},
                "geometry": { "type": "Point", "coordinates": [ -2.394801732341264, 51.343192672209916, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "11"},
                "geometry": { "type": "Point", "coordinates": [ -2.394801732341264, 51.343192672209916, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "12"},
                "geometry": { "type": "Point", "coordinates": [ -2.394801732341264, 51.343192672209916, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "13"},
            "geometry": { "type": "Point", "coordinates": [-2.3953810588485602, 51.34327980307925, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "14"},
                "geometry": { "type": "Point", "coordinates": [-2.3953810588485602, 51.34327980307925, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "115"},
                "geometry": { "type": "Point", "coordinates": [-2.3953855237360535, 51.34327628372822, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "161"},
                "geometry": { "type": "Point", "coordinates": [-2.3953855237360535, 51.34327628372822, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "116"},
                "geometry": { "type": "Point", "coordinates": [-2.3953855237360535, 51.34327628372822, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "191"},
                "geometry": { "type": "Point", "coordinates": [-2.3953794119610677, 51.34328272643038, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "118"},
                "geometry": { "type": "Point", "coordinates": [-2.3953794119610677, 51.34328272643038, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "117"},
                "geometry": { "type": "Point", "coordinates": [-2.3953794119610677, 51.34328272643038, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "110"},
                "geometry": { "type": "Point", "coordinates": [-2.3953794119610677, 51.34328272643038, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "111"},
                "geometry": { "type": "Point", "coordinates": [-2.3943778447753523, 51.34327750219438, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "112"},
                "geometry": { "type": "Point", "coordinates": [-2.3963778447753523, 51.34327750219438, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "114"},
                "geometry": { "type": "Point", "coordinates": [-2.395431219508018, 51.343172182739574, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "113"},
                "geometry": { "type": "Point", "coordinates": [-2.395901219508018, 51.343372182739574, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "115"},
                "geometry": { "type": "Point", "coordinates": [-2.395401219508018, 51.343472182739574, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "115"},
                "geometry": { "type": "Point", "coordinates": [-2.3960677043511933, 51.34236164740924, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "115"},
                "geometry": { "type": "Point", "coordinates": [-2.3960677043511933, 51.34246164740924, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "115"},
                "geometry": { "type": "Point", "coordinates": [-2.3961677043511933, 51.34246164740924, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "115"},
                "geometry": { "type": "Point", "coordinates": [-2.3960677043511933, 51.34226164740924, 0.0 ] }
            },
            {
                "type": "Feature",
                "properties": { "id": "115"},
                "geometry": { "type": "Point", "coordinates": [-2.3961677043511933, 51.34226164740924, 0.0 ] }
            },
            ]
}

export default function HerdHeatmap () {
    const DrawControlStyle = [{
        "id": "gl-draw-polygon-fill-static",
        "type": "fill",
        "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
        "paint": {
            "fill-color": "rgb(255, 162, 0)",
            "fill-outline-color": "rgb(255, 162, 0)",
            "fill-opacity": 0.1
        }
    },
                              {
                                  "id": "gl-draw-polygon-stroke-static",
                                  "type": "line",
                                  "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                                  "layout": {
                                      "line-cap": "round",
                                      "line-join": "round"
                                  },
                                  "paint": {
                                      "line-color": "rgb(255, 162, 0)",
                                      "line-dasharray": [0.2, 2],
                                      "line-opacity": 0.8,
                                      "line-width": 2
                                  }
                              }]

    const BathFieldFeature = {
        "id": "6d0f9797966c5f9167ecf732cb4fecc8",
        "type": "Feature",
        "properties": {},
        "geometry": {
            "coordinates": [
                [
                    [
                        -2.396540269908911,
                        51.343657785647395
                    ],
                    [
                        -2.3962526842701095,
                        51.342870893712984
                    ],
                    [
                        -2.3969150393272685,
                        51.342758257505665
                    ],
                    [
                        -2.39624175124996,
                        51.34194163309229
                    ],
                    [
                        -2.392573618719581,
                        51.343015131050095
                    ],
                    [
                        -2.3932050797553757,
                        51.34412673899703
                    ],
                    [
                        -2.396540269908911,
                        51.343657785647395
                    ]
                ]
            ],
            "type": "Polygon"
        }
    };

    function DrawControl(props) {
        const Draw = useRef(new MapboxDraw(props));

        useControl(
                () => Draw.current,
                ({map}) => {
                    map.on('load', () => {
                        Draw.current.add(BathFieldFeature);
                    })
                },
                () => {}
                );

        return null;
    }

    const modes = MapboxDraw.modes;
    modes.static = StaticMode;

    return (
            <div className="Container">
                <div className="Title">Hotspots</div>
                <div className="HeatmapMap">
                <Map
                    initialViewState={{
                        longitude: -2.394801732341264,
                        latitude: 51.343192672209916,
                        zoom: 15
                }}
                    style={{width: "100%", height: "100%", borderRadius: "10px"}}
                    mapStyle="mapbox://styles/mapbox/dark-v9"
                    mapboxAccessToken="pk.eyJ1Ijoiam9laGQiLCJhIjoiY2xnOGRlNGs2MG16MDNzcGttMjFtYnM0OCJ9.Vjsp2UVv9YOyciXW4Nzvjg"
                    >
                    <Source type="geojson" data={data}>
                        <Layer {...heatmapLayer} />
                    </Source>
                    <DrawControl
                        displayControlsDefault={false}
                        defaultMode="static"
                        modes={modes}
                        styles={
                        DrawControlStyle
                    }
                    />
                    </Map>
                    </div>
            </div>
            )
}