import './App.css';
import NavigationBar from "./components/NavigationBar"
import HerdMap from "./components/HerdMap"
import HerdHeatmap from "./components/HerdHeatmap"
import FarmTimeline from "./components/FarmTimeline"
import FarmSummary from "./components/FarmSummary"


function App() {
    return (
        <div className="App">
            <NavigationBar/>
            <div className="MainContainer">
                <div className="LeftContainer">
                    <div className="ChildContainer">
                    <HerdMap/>
                    </div>
                        <div className="ChildContainer">
                    <HerdHeatmap/>
                        </div>
                </div>
                <div className="RightContainer">
                    <div className="ChildContainer">
                        <FarmSummary/>
                    </div>
                    <div className="ChildContainer">
                        <FarmTimeline/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
