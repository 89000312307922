import "./NavigationBar.css";
import logo from '../assets/logo_nobg.png';
import { BiCog } from 'react-icons/bi';


export default function NavigationBar () {
    return (
            <div className="Container">
                <div className="Bar">
                    <div className="Logo">
                        <img src={logo} width={80} alt="Bullseye logo depicting a cow with a biometric tracker strap"/>
                        <span className="Logo-text">Bullseye</span>
                    </div>

                    <div className="Menu">
                        <div className="Menu-option">Fields</div>
                        <div className="Menu-option">Cows</div>
                        <div className="Menu-option">Assets</div>
                    </div>

                    <div className="OrderSettingsContainer">
                        <div className="Order">
                            Order More Collars
                        </div>
                        <BiCog size={50} className="Settings"/>
                    </div>
                </div>
            </div>
    )
}